<template>
  <div id="reservationContainer" class="relative">

        <vs-tabs class="py-0 reservationTabs" v-model="activeTab">
          <vs-tab label="Podatki o rezervaciji" key="0">
            <div class="p-0">
              <div v-if="reservationData">

                <vs-list>
                  <vs-list-header icon="supervisor_account" title="Potnik"></vs-list-header>
<!--                  <vs-list-item class="customer"-->
<!--                                :title="reservationData.customer.first_name + ' ' + reservationData.customer.last_name"-->
<!--                                :subtitle="reservationData.customer.notes"></vs-list-item>-->

                  <vs-list-item class="customer" :title="reservationData.customer.first_name + ' ' + reservationData.customer.last_name"
                                 :subtitle="reservationData.customer.notes">
                    <template slot="avatar">
                      <vs-avatar size="60px" :src="reservationData.customer.gender === 'male' ? sir : madam" :color="reservationData.customer.gender === 'male' ? '#dff8ff' : '#ffffdf'"  />
                    </template>
                  </vs-list-item>


                  <vs-list-header icon="directions_car" title="Rezervacija"></vs-list-header>
                  <vs-list-item
                    icon="note"
                    title="Opomba"
                    :subtitle="reservationData.reservations_notes ? reservationData.reservations_notes : ''"></vs-list-item>

                  <vs-list-item
                    v-if="reservationData.purpose"
                    :icon="$globalFunctions.getReservationPurposeIcon(reservationData.purpose.id)"
                    title="Glavni namen poti"
                    :subtitle="reservationData.purpose.name ? reservationData.purpose.name : '/'"></vs-list-item>

                  <vs-list-item icon="event" title="Datum"
                                :subtitle="moment(reservationData.departure_time).format('DD. MM. YYYY')"></vs-list-item>
                  <vs-list-item icon="schedule" title="Čas"
                                :subtitle="moment(reservationData.departure_time).format('HH:mm') + '-' + moment(reservationData.arrival_time).format('HH:mm')"></vs-list-item>


                  <vs-list-header icon="home" title="Načrt poti" color="success"></vs-list-header>


                  <vs-collapse class="p-0">
                    <vs-collapse-item>
                      <div slot="header">
                        <vs-list-item v-if="routeDistanceTotal" title="Skupna razdalja"
                                      :subtitle="Math.round(routeDistanceTotal/1000).toString() + ' km'">
                          <template slot="avatar">
                            <vs-avatar text="skupaj"/>
                          </template>
                        </vs-list-item>
                      </div>

                      <vs-list-item v-if="routeDistanceAB" title="Razdalja od A do B"
                                    :subtitle="Math.round(routeDistanceAB/1000).toString() + ' km'">
                        <template slot="avatar">
                          <vs-avatar text="AB"/>
                        </template>
                      </vs-list-item>
                      <vs-list-item v-if="routeDistanceBC" title="Razdalja od B do C"
                                    :subtitle="Math.round(routeDistanceBC/1000).toString() + ' km'">
                        <template slot="avatar">
                          <vs-avatar text="BC"/>
                        </template>
                      </vs-list-item>


                    </vs-collapse-item>

                    <vs-collapse-item>
                      <div slot="header">
                        <vs-list-item v-if="routeTravelTimeTotal" title="Skupni čas potovanja"
                                      :subtitle="$globalFunctions.formatSecondsToReadableDuration(routeTravelTimeTotal)">
                          <template slot="avatar">
                            <vs-avatar text="skupaj"/>
                          </template>
                        </vs-list-item>
                      </div>

                      <vs-list-item v-if="routeTravelTimeAB" title="Čas potovanja od A do B"
                                    :subtitle="$globalFunctions.formatSecondsToReadableDuration(routeTravelTimeAB)">
                        <template slot="avatar">
                          <vs-avatar text="AB"/>
                        </template>
                      </vs-list-item>
                      <vs-list-item v-if="routeTravelTimeBC" title="Čas potovanja od B do C"
                                    :subtitle="$globalFunctions.formatSecondsToReadableDuration(routeTravelTimeBC)">
                        <template slot="avatar">
                          <vs-avatar text="BC"/>
                        </template>
                      </vs-list-item>

                    </vs-collapse-item>
                  </vs-collapse>

                  <template>
                    <div>
                      <gmap-map ref="map" :center="center" :zoom="15" style="width: 100%; height: 500px">
                        <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                                          @closeclick="infoWinOpen=false">

                        </gmap-info-window>

                        <DirectionsRenderer/>
                      </gmap-map>
                    </div>
                  </template>

                  <vs-list-header icon="home" title="Mesto odhoda" color="success"></vs-list-header>
                  <vs-list-item icon="location_on" :title="reservationData.departure.address_name"
                                :subtitle="reservationData.departure.quick_search"></vs-list-item>

                  <vs-list-header icon="apartment" title="Mesto prihoda" color="success"></vs-list-header>
                  <vs-list-item icon="location_on" :title="reservationData.destination.address_name"
                                :subtitle="reservationData.destination.quick_search"></vs-list-item>

                  <vs-list-header icon="home" title="Mesto povratka" color="success"></vs-list-header>
                  <vs-list-item icon="location_on" :title="reservationData.arrival.address_name"
                                :subtitle="reservationData.arrival.quick_search"></vs-list-item>

                  <vs-list-header icon="favorite" title="Prostovoljec" color="success"></vs-list-header>
                  <vs-list-item v-if="!reservationData.volunteer_id" icon="clear"
                                title="Prostovoljec še ni določen"></vs-list-item>
                  <vs-list-item v-if="reservationData.volunteer_id"
                                :title="reservationData.volunteer.first_name + ' ' + reservationData.volunteer.last_name"
                                :subtitle="reservationData.volunteer.notes">
                    <template slot="avatar">
                      <vs-avatar size="60px" :src="$globalFunctions.getUserAvatar(reservationData.volunteer.profile_image)"/>
                    </template>
                  </vs-list-item>

                </vs-list>


                <vs-row
                  vs-align="center"
                  vs-type="flex" vs-justify="space-around" vs-w="12" class="mt-2 mb-2">
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                    <vs-button color="primary" icon="edit" @click="editReservation">Uredi</vs-button>
                  </vs-col>

                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                    <vs-button color="success" icon="content_copy" @click="duplicateReservation">Podvoji</vs-button>
                  </vs-col>

                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                    <vs-button color="danger" icon="delete" @click="beforeDeleteReservation">Izbriši</vs-button>
                  </vs-col>
                </vs-row>

                <!--      <vs-button color="primary" icon="edit">Uredi</vs-button>-->
                <!--      <vs-button color="danger" icon="delete">Izbriši</vs-button>-->


              </div>
            </div>
          </vs-tab>
          <vs-tab label="Kuponi za popust" key="1">
            <div class="p-0">
              <Coupon :reservationId="reservationId"></Coupon>
            </div>
          </vs-tab>
        </vs-tabs>


  </div>
</template>

<script>
  import Vue from 'vue';
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import interactionPlugin from '@fullcalendar/interaction';
  import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
  import Coupon from "../donations/Coupon";
  import sir from '../../../assets/images/portrait/sir.svg';
  import madam from '../../../assets/images/portrait/madam.svg';

  let directionsService;
  let directionsDisplay;

  export default {
    name: 'reservation',

    components: {
      DirectionsRenderer,
      Coupon
    },
    props: {
      reservationId: Number
    },
    data() {
      return {
        sir: sir,
        madam: madam,
        reservationData: null,
        routeDistanceAB: null,
        routeDistanceBC: null,
        routeDistanceTotal: null,
        routeTravelTimeAB: null,
        routeTravelTimeBC: null,
        routeTravelTimeTotal: null,
        center: {lat: 46.0569, lng: 14.5058},
        infoContent: '',
        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,
        infoOptions: {
          pixelOffset: {width: 0, height: -35}
        },
        activeTab: 0
      }
    },

    computed: {},

    mounted() {
      const _this = this;

      _this.activeTab = 0;
      _this.getReservation();

    },

    watch: {
      reservationId: {
        handler(val, oldVal) {
          console.log("CHANGE!!!!");
          this.reservationData = null;
          this.routeDistanceAB = null;
          this.routeDistanceBC = null;
          this.routeDistanceTotal = null;
          this.routeTravelTimeAB = null;
          this.routeTravelTimeBC = null;
          this.routeTravelTimeTotal = null;

          if (val) {
            this.getReservation();
            this.activeTab = 0;
          }
        }
      }
    },

    methods: {
      getReservation() {
        const _this = this;

        _this.$vs.loading();

        Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'reservations/' + _this.reservationId)
          .then((res) => {

            _this.reservationData = res.data.data;
            console.log("TOLE: ", res.data.data);

            console.log("mapa:", _this.$refs['map']);

            setTimeout(function () {
              directionsService = new google.maps.DirectionsService;
              directionsDisplay = new google.maps.DirectionsRenderer;
              if(_this.$refs.map) {
                directionsDisplay.setMap(_this.$refs.map.$mapObject);
              }

              _this.setMapsDirections();

              _this.$vs.loading.close();
            }, 500)


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      setMapsDirections() {
        const _this = this;

        directionsService.route({
          origin: _this.reservationData.departure.quick_search,
          waypoints: [{
            location: _this.reservationData.destination.quick_search,
            stopover: true
          }],
          destination: _this.reservationData.arrival.quick_search,
          optimizeWaypoints: false,
          travelMode: google.maps.TravelMode.DRIVING
        }, function (response, status) {
          if (status === 'OK') {
            console.log("response --->", response.routes);
            _this.routeDistanceAB = response.routes[0].legs[0].distance.value;
            _this.routeDistanceBC = response.routes[0].legs[0].distance.value;
            _this.routeDistanceTotal = response.routes[0].legs[0].distance.value + response.routes[0].legs[1].distance.value;

            _this.routeTravelTimeAB = response.routes[0].legs[0].duration.value;
            _this.routeTravelTimeBC = response.routes[0].legs[1].duration.value;
            _this.routeTravelTimeTotal = response.routes[0].legs[0].duration.value + response.routes[0].legs[1].duration.value;
            directionsDisplay.setDirections(response);
          } else {
            console.error('Directions request failed due to ' + status);
          }
        });
      },

      editReservation() {
        const _this = this;
        _this.$router.push({name: 'Rezervacija', params: {reservationId: _this.reservationId}});
      },

      beforeDeleteReservation() {
        const _this = this;

        Vue.$confirm({
          title: 'Ste prepričani, da želite izbrisati rezervacijo uporabnika ' + _this.reservationData.customer.first_name + ' ' + _this.reservationData.customer.last_name + ' ?',
          message: 'Po potrditvi bo rezervacija trajno izbrisana.',
          button: {
            yes: 'Izbriši',
            no: 'Prekliči'
          },
          callback: confirm => {
            console.log("confirm", confirm);
            if(confirm) {
              _this.deleteReservation();
            }
          }
        });
      },

      deleteReservation() {
        const _this = this;

        _this.$vs.loading();

        Vue.prototype.$http.delete(Vue.prototype.$config.api.productionOld + 'reservations/' + _this.reservationId)
          .then((res) => {

              _this.$emit('closeSideBar', true);
              _this.$vs.loading.close();

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      duplicateReservation() {
        const _this = this;

        _this.$vs.loading();
        let reservation = _this.reservationData;

        reservation.id = null;
        reservation.created_at = new Date().toISOString();
        reservation.updated_at = new Date().toISOString();
        reservation.departure_time = new Date().toISOString();
        reservation.arrival_time = new Date().toISOString();
        reservation.volunteer_id = null;
        reservation.departure_address = {
          id: reservation.departure.id, //Update
          quick_search: (typeof reservation.departure.quick_search === "undefined" ? null : reservation.departure.quick_search.formatted_address) || reservation.departure.quick_search, //reservation.departure.quick_search.formatted_address || reservation.departure.quick_search,
          address_name: reservation.departure.address_name,
          route: reservation.departure.route,
          street_number: reservation.departure.street_number,
          postal_code: reservation.departure.postal_code,
          postal_town: reservation.departure.postal_town,
          municipality: reservation.departure.municipality,
          country: reservation.departure.country
        };
        reservation.arrival_address = {
          id: reservation.arrival.id, //Update
          quick_search: (typeof reservation.arrival.quick_search === "undefined" ? null : reservation.arrival.quick_search.formatted_address) || reservation.arrival.quick_search, //reservation.arrival.quick_search.formatted_address || reservation.arrival.quick_search,
          address_name: reservation.arrival.address_name,
          route: reservation.arrival.route,
          street_number: reservation.arrival.street_number,
          postal_code: reservation.arrival.postal_code,
          postal_town: reservation.arrival.postal_town,
          municipality: reservation.arrival.municipality,
          country: reservation.arrival.country
        };

        console.log("reservation after: ", reservation);



        Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'reservations/', reservation)
          .then((res) => {

            console.log("res", res.data);
            _this.$vs.loading.close();
            _this.$router.push({name: 'Rezervacija', params: {reservationId: res.data.data.id, createdViaDuplicateMethod: true}});

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      },
    }
  }
</script>

